import React from 'react';
import { useTheme } from '@mui/material/styles';
import { GlobalStyles as GlobalThemeStyles, styled as muiStyled } from '@mui/material';

const GlobalStyle = () => {
  const theme = useTheme();

  return (
    <GlobalThemeStyles
      styles={{
        '*': {
          margin: 0,
          padding: 0,
          boxSizing: 'border-box',
          fontFamily: 'Roboto, sans-serif'
        },
        body: {
          minHeight: '100vh',
          maxWidth: '100vw',
          overflowX: 'hidden',
          background: theme.palette.background.default,
          fontSize: '20px'
        }
      }}
    />
  );
};

export const Container = muiStyled('div')(({ theme }) => ({
  padding: '0 15px',
  margin: '0 auto',
  [theme.breakpoints.up('sm')]: {
    maxWidth: '750px'
  }
}));

export default GlobalStyle;
