const GREY = {
  0: '#FFFFFF',
  100: '#F9FAFB',
  200: '#F4F6F8',
  300: '#DFE3E8',
  400: '#C4CDD5',
  500: '#919EAB',
  600: '#637381',
  700: '#454F5B',
  800: '#212B36',
  900: '#161C24'
};

// Generated using https://colors.eva.design/
const PRIMARY = {
  lighter: '#F6C482',
  light: '#EEAA62',
  main: '#E38431',
  dark: '#C36523',
  darker: '#83330F'
};

const COMMON = {
  common: { black: '#000', white: '#fff', orange: '#e38431', purple: '#833AB4', red: '#f03c3c' },
  primary: { ...PRIMARY, contrastText: '#fff' }
};

const palette = {
  light: {
    ...COMMON,
    text: { primary: '#0d2d3f', secondary: '#444', disabled: '#545454' },
    divider: '#888',
    background: { paper: '#C6C5C1', default: '#f8f6f0' }
  },
  dark: {
    ...COMMON,
    text: { primary: '#e5e5e5', secondary: '#c0bfbf', disabled: GREY[600] },
    divider: GREY[600],
    background: { paper: GREY[800], default: GREY[900] }
  }
};

export default palette;
