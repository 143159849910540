import { styled as muiStyled } from '@mui/material';

const speed = 200;
const delay = 0.12;

const beforeOpen = `top ${speed}ms ease`;
const beforeClosed = `top ${speed}ms ${delay}s ease, transform ${speed}ms ${delay}s ease`;

const spanOpen = `transform ${speed}ms ${delay}s ease`;
const spanClosed = `transform ${speed}ms ease`;

const afterOpen = `top ${speed}ms ease, transform ${speed}ms ${delay}s ease`;
const afterClosed = ` top ${speed}ms ${delay}s ease, transform ${speed}ms ease`;

export const Hamburger = muiStyled('div')<{ open: boolean }>(({ theme, open }) => ({
  cursor: 'pointer',
  width: '24px',
  color: theme.palette.common.white,
  height: '24px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'all 0.5s ease',
  [theme.breakpoints.up('sm')]: {
    display: 'none'
  },

  span: {
    display: 'block',
    width: '100%',
    borderRadius: '10px',
    backgroundColor: theme.palette.text.primary,
    height: '3px',
    position: 'relative',
    transition: open ? spanOpen : spanClosed,
    transform: open ? 'rotate(45deg)' : 'rotate(0deg)',

    '&::before, &::after': {
      position: 'absolute',
      content: '""',
      display: 'block',
      width: 'inherit',
      borderRadius: 'inherit',
      height: 'inherit',
      top: 0,
      left: 0,
      background: 'inherit'
    },

    '&::before': {
      transition: open ? beforeOpen : beforeClosed,
      top: open ? 0 : '-7px'
    },

    '&::after': {
      transition: open ? afterOpen : afterClosed,
      top: open ? 0 : '7px',
      transform: open ? 'rotate(-90deg)' : 'rotate(0)'
    }
  }
}));
