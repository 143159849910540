import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { Heading, HeadingTwo, MessageWrap, Wrap } from './NotFoundStyle';

const NotFound = () => {
  const navigate = useNavigate();
  return (
    <Wrap>
      <Helmet>
        <title>John Oshalusi | 404 </title>
        <meta name="description" content="Page not found" />
      </Helmet>
      <Heading>404</Heading>
      <MessageWrap>
        <HeadingTwo>Sorry we couldn&apos;t find this page.</HeadingTwo>
        <Typography variant="body1" sx={{ margin: '1.2rem 0' }}>
          But don&apos;t worry, you can find plenty of other things on our homepage.
        </Typography>
        <Button color="primary" variant="contained" onClick={() => navigate('/')}>
          Go to Homepage
        </Button>
      </MessageWrap>
    </Wrap>
  );
};

export default NotFound;
