export interface UsesListType {
  key: string;
  label: string;
  items: string[];
}

export const usesList: UsesListType[] = [
  {
    key: 'hardware',
    label: 'Hardware',
    items: [
      'MacBook Pro',
      'HP Envy',
      'iPhone 13 Pro Max',
      'Redmi Note 10 Pro',
      'Oraimo Earphone',
      'Oraimo BoomPop'
    ]
  },
  {
    key: 'software',
    label: 'Software',
    items: [
      'Google Chrome (default browser)',
      'Lastpass (password manager)',
      'Webstorm (default JS and TS IDE)',
      'PyCharm (default Python IDE)',
      'IntelliJ (default IDE for almost anything else)',
      'Visual Studio Code (code editor)',
      'DataGrip (favourite database IDE)',
      'iTerm + Oh My Zsh + Fig (terminal)',
      'Todoist (to-do list)',
      'Notion (notes)',
      'Spotify (music)',
      'Slack (professional communication)',
      'Postman (API testing)',
      'Docker (containerization)',
      'ColorSlurp (system-wide eyedrop tool)'
    ]
  },
  {
    key: 'chrome-extensions',
    label: 'Chrome Extensions',
    items: [
      'Lace Wallet',
      'Wappalyzer',
      'Grammarly',
      'React Developer Tools',
      'Redux DevTools',
      'JSONVue',
      'MetaMask',
      'Lastpass',
      'Vimeo Record'
    ]
  }
];
