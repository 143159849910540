import React from 'react';
import { Typography } from '@mui/material';

import { useTheme } from '@mui/material/styles';
import { CopyRight, FooterContainer, ShinyText, Wrap } from './footerStyle';
import { SocialLinks } from './SocialLinks';

const weekDays: string[] = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Monday'
];

const Footer = () => {
  const theme = useTheme();
  return (
    <Wrap>
      <FooterContainer>
        <SocialLinks />
        <CopyRight aria-label="copyright">
          <Typography variant="caption" fontWeight="bold" color={theme.palette.text.primary}>
            &copy; {new Date().getFullYear()} John &nbsp;
            <ShinyText>Have a good {weekDays[new Date().getDay()]} &#10084;</ShinyText>
          </Typography>
        </CopyRight>
      </FooterContainer>
    </Wrap>
  );
};

export default Footer;
