import { styled as muiStyled } from '@mui/material';

export const UsesList = muiStyled('li')(({ theme }) => ({
  color: theme.palette.text.primary,
  listStylePosition: 'inside',
  listStyle: '-',
  marginBottom: '0.3rem',
  paddingLeft: '0.3rem',
  fontSize: '1.2rem',
}));
