import React, { ReactNode } from 'react';
import { Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';

interface Props {
  children: ReactNode;
}

const TextWrapper = ({ children }: Props) => {
  const theme = useTheme();
  return (
    <Typography
      fontSize="large"
      justifyContent="center"
      color={theme.palette.text.primary}
      sx={{ mb: 1.5 }}
    >
      {children}
    </Typography>
  );
};

export default TextWrapper;
