import { styled as muiStyled } from '@mui/material';
import { Container } from '../../theme/globalStyles';

const AppWrap = muiStyled(Container)(() => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  minHeight: '100vh'
}));

export default AppWrap;
