import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { MdDarkMode, MdLightMode } from 'react-icons/md';
import LogoLight from '../../assets/images/John_logo_light.png';
import LogoDark from '../../assets/images/John_logo_dark.png';

import { Nav, NavContainer, NavLinkItem, NavLinksContainer, NavLogo } from './NavbarStyle';

import { Hamburger } from './Hamburger';
import { useSettings } from '../../context/SettingsContext';

const Navbar = () => {
  const [open, setOpen] = useState<boolean>(false);
  const { themeMode, changeTheme } = useSettings();

  const handleClick = (): void => {
    setOpen(!open);
  };

  return (
    <Nav>
      <NavContainer>
        <NavLogo to="/">
          <img src={themeMode === 'dark' ? LogoDark : LogoLight} alt="john" />
        </NavLogo>
        <NavLinksContainer open={open}>
          <NavLinkItem onClick={() => setOpen(false)}>
            <a href={process.env.REACT_APP_MEDIUM_URL} target="_blank" rel="noopener noreferrer">
              Articles
            </a>
          </NavLinkItem>
          <NavLinkItem onClick={() => setOpen(false)}>
            <Link to="/uses">Uses</Link>
          </NavLinkItem>
          <NavLinkItem
            className="switch"
            id="ul-switch"
            aria-label="change theme"
            onClick={() => changeTheme(themeMode === 'light' ? 'dark' : 'light')}
          >
            {themeMode === 'light' ? <MdDarkMode size="27" /> : <MdLightMode size="27" />}
          </NavLinkItem>
        </NavLinksContainer>
        <NavLinkItem
          as="div"
          className="switch"
          id="nav-switch"
          aria-label="change theme"
          onClick={() => changeTheme(themeMode === 'light' ? 'dark' : 'light')}
        >
          {themeMode === 'light' ? <MdDarkMode size="27" /> : <MdLightMode size="27" />}
        </NavLinkItem>
        <Hamburger onClick={handleClick} open={open}>
          <span />
        </Hamburger>
      </NavContainer>
    </Nav>
  );
};

export default Navbar;
