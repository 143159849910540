import React from 'react';
import { styled as muiStyled } from '@mui/material';

const Line = muiStyled('span')(({ theme }) => ({
  display: 'block',
  width: '100%',
  height: '1px',
  background: `${theme.palette.divider}`,
  marginTop: '30px'
}));

const Divider = () => <Line aria-hidden="true" />;

export default Divider;
