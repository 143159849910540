import { styled as muiStyled } from '@mui/material';

export const StyledLink = muiStyled('a')(({ theme }) => ({
  textDecoration: 'none',
  color: theme.palette.text.secondary,
  fontWeight: 600,
  borderBottom: `3px solid ${theme.palette.common.orange}`,
  marginBottom: '-1px',
  position: 'relative',

  '&::before': {
    content: '" "',
    position: 'absolute',
    width: '100%',
    height: '0',
    background: theme.palette.common.orange,
    bottom: 0,
    left: 0,
    transition: 'height 200ms cubic-bezier(.3,0,.5,1)',
    zIndex: '-1'
  },

  '&:hover': {
    color: theme.palette.common.white,

    '&::before': {
      height: '110%'
    }
  }
}));

export default StyledLink;
