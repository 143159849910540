import React, { createContext, ReactNode, useEffect, useMemo, useState } from 'react';

type ThemeMode = 'light' | 'dark';

type SettingsContextType = {
  themeMode: ThemeMode;
  changeTheme: (themeMode: ThemeMode) => void;
};

const SettingsContext = createContext<SettingsContextType | null>(null);

const SettingsProvider = ({ children }: { children: ReactNode }) => {
  const [themeMode, setThemeMode] = useState<ThemeMode>('light');

  const changeTheme = (theme: ThemeMode) => {
    localStorage.setItem('mode', theme);
    setThemeMode(theme);
  };

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const preferredTheme = localStorage.getItem('mode') as ThemeMode;
    let userAgentColorScheme: ThemeMode = 'light';
    if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
      userAgentColorScheme = 'dark';
    }
    changeTheme(preferredTheme || userAgentColorScheme);

    if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
      const media = window.matchMedia('(prefers-color-scheme: dark)');
      const change = (e: MediaQueryListEvent) => changeTheme(e.matches ? 'dark' : 'light');
      media.addEventListener('change', change);

      return () => media.removeEventListener('change', change);
    }
  }, []);

  const settingValue = useMemo(
    () => ({
      themeMode,
      changeTheme
    }),
    [themeMode]
  );

  return <SettingsContext.Provider value={settingValue}>{children}</SettingsContext.Provider>;
};

export const useSettings = () => {
  const context = React.useContext(SettingsContext);
  if (!context) {
    throw new Error('useSettings must be used within a SettingsProvider');
  }
  return context;
};

export default SettingsProvider;
