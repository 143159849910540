import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { styled as muiStyled } from '@mui/material';

export const navHeight = '60px';
const navSpace = '3.5rem';
const blurAmount = '5px';

export const Nav = muiStyled('nav')(({ theme }) => ({
  padding: `${navSpace} 0`,
  width: '100%',
  height: `${navHeight}`,
  backdropFilter: `blur(${blurAmount})`,
  position: 'sticky',
  zIndex: 100,
  top: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  transition: 'all 0.5s ease',
  margin: '0 auto',
  a: {
    textDecoration: 'none',
    color: theme.palette.text.primary,
    cursor: 'pointer'
  }
}));

export const NavContainer = muiStyled('div')(({ theme }) => ({
  width: '100%',
  height: 'inherit',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',

  '.switch': {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: 'max-content',
    cursor: 'pointer',
    borderRadius: '0.3rem',
    padding: '0.3rem 0.6rem',
    color: theme.palette.text.primary,

    '&:hover': {
      backgroundColor: theme.palette.background.paper
    }
  },

  '#ul-switch': {
    display: 'none'
  },

  '#nav-switch': {
    alignSelf: 'center',
    position: 'absolute',
    right: '4rem'
  },

  [theme.breakpoints.up('sm')]: {
    '#nav-switch': {
      display: 'none'
    },

    '#ul-switch': {
      display: 'block'
    }
  }
}));

export const NavLogo = styled(Link)`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    object-fit: cover;
    width: 50px;
    display: block;
  }
`;

export const NavLinksContainer = muiStyled('ul')<{ open: boolean }>(({ theme, open }) => ({
  listStyle: 'none',
  display: 'flex',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    paddingTop: '1rem',
    paddingLeft: '2rem',
    position: 'absolute',
    top: `calc(${navHeight} + ${navSpace})`,
    backgroundColor: theme.palette.background.default,
    backdropFilter: `blur(${blurAmount})`,
    margin: '0',
    width: '100vw',
    height: `calc(100vh - (${navHeight} + ${navSpace}))`,
    right: open ? 0 : '120%',
    transition: 'all 0.5s ease'
  }
}));

export const NavLinkItem = muiStyled('li')(({ theme }) => ({
  fontWeight: 600,
  margin: '0 24px',
  color: theme.palette.text.primary,

  a: {
    borderRadius: '0.3rem',
    padding: '0.4rem 0.3rem',
    transition: 'all 0.1s ease'
  },

  '&:hover': {
    textDecoration: 'underline',
    textDecorationThickness: '3px'
  },

  [theme.breakpoints.down('sm')]: {
    alignSelf: 'flex-start',
    fontSize: '1.4rem',
    margin: '2rem 0',

    a: {
      fontSize: '1.7rem',
      color: theme.palette.text.primary
    },
    '&:hover': {
      backgroundColor: 'transparent'
    }
  }
}));
