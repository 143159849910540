import React from 'react';
import { Box, Typography } from '@mui/material';
import { Helmet } from 'react-helmet';
import { useTheme } from '@mui/material/styles';

import { usesList, UsesListType } from './UsesList';
import { UsesList } from './UsesStyle';
import Divider from '../../components/common/Divider';

const Uses: React.FC = () => {
  const theme = useTheme();
  return (
    <>
      <Helmet>
        <title>John Oshalusi | Uses </title>
        <meta name="description" content="Uses" />
      </Helmet>
      <Box sx={{ mt: 3 }}>
        <Box sx={{ mb: 2 }}>
          <Typography variant="h5" fontWeight="bold" color={theme.palette.text.primary}>
            Uses
          </Typography>
          <Typography variant="subtitle1" color={theme.palette.text.secondary} fontStyle="italic">
            Last updated: 27/11/2023
          </Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
          {usesList.map((use: UsesListType, index: number) => (
            <Box sx={{ mb: 4 }} key={index}>
              <Typography
                sx={{ mb: 1 }}
                variant="h5"
                key={use.key}
                id={use.label}
                color={theme.palette.text.primary}
              >
                {use.label}
              </Typography>
              <ul key={index} aria-labelledby={use.label}>
                {use.items.map((item: string, itemIndex: number) => (
                  <UsesList key={itemIndex}>{item}</UsesList>
                ))}
              </ul>
            </Box>
          ))}
        </Box>
      </Box>
      <Divider />
    </>
  );
};

export default Uses;
