import { styled as muiStyled } from '@mui/material';

export const SocialLinksContainer = muiStyled('div')(() => ({
  width: 'fit-content',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center'
}));

export const SocialIcon = muiStyled('a')(({ theme }) => ({
  color: theme.palette.text.primary,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  fontSize: '1.5rem',
  margin: '0 0.3rem',
  padding: '0.2rem',
  cursor: 'pointer',
  transition: 'transform 0.5s',
  '&:hover': {
    transform: 'rotateY(360deg)'
  }
}));
