import React, { useEffect } from 'react';
import ReactGA from 'react-ga';
import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom';

import GlobalStyle from './theme/globalStyles';
import { Footer } from './components/footer';
import { Navbar } from './components/navbar';
import { routes as appRoutes } from './routes/routes';
import { ThemeConfig } from './theme';
import SettingsProvider from './context/SettingsContext';
import NotFound from './pages/NotFound/NotFound';
import AppWrap from './components/common/AppWrap';

const Main = () => {
  const location = useLocation();
  useEffect(() => {
    // initialize google analytics
    ReactGA.initialize(`'${process.env.REACT_APP_TRACKING_ID}'`);

    ReactGA.set({ page: location.pathname });
    ReactGA.pageview(location.pathname);
  }, [location]);

  return (
    <>
      <GlobalStyle />
      <AppWrap>
        <Navbar />
        <Routes>
          {appRoutes.map((route) => (
            <Route key={route.key} path={route.path} element={<route.component />} />
          ))}
          <Route path="*" element={<NotFound />} />
        </Routes>
        <Footer />
      </AppWrap>
    </>
  );
};
function App() {
  return (
    <SettingsProvider>
      <ThemeConfig>
        <Router>
          <Main />
        </Router>
      </ThemeConfig>
    </SettingsProvider>
  );
}
export default App;
