import { FC } from 'react';

import Landing from '../pages/Landing/Landing';
import Uses from '../pages/Uses/Uses';

interface Routes {
  key: string;
  path: string;
  component: FC;
}

export const routes: Routes[] = [
  {
    key: 'home-route',
    path: '/',
    component: Landing,
  },
  {
    key: 'uses-route',
    path: '/uses',
    component: Uses,
  },
];
