import { styled as muiStyled } from '@mui/material';
import { Container } from '../../theme/globalStyles';

export const Wrap = muiStyled(Container)(() => ({
  width: '100%',
  padding: '1rem 0',
  display: 'flex',
  justifyContent: 'flex-end',
  flexDirection: 'column',
  marginTop: 'auto'
}));

export const FooterContainer = muiStyled('footer')(({ theme }) => ({
  marginTop: '0.3rem',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',

  [theme.breakpoints.up('sm')]: {
    flexDirection: 'row-reverse',
    justifyContent: 'space-between'
  }
}));

export const CopyRight = muiStyled('p')(({ theme }) => ({
  marginTop: '0.6rem',
  width: 'fit-content',
  [theme.breakpoints.up('sm')]: {
    marginTop: 0
  }
}));

export const ShinyText = muiStyled('span')(({ theme }) => ({
  background: `linear-gradient(90deg, ${theme.palette.common.purple} 0%, ${theme.palette.common.red} 100%)`,
  WebkitBackgroundClip: 'text',
  WebkitTextFillColor: 'transparent',
  backgroundSize: '400% 400%',
  animation: 'shimmer 2s infinite ease',

  '@keyframes shimmer': {
    '0%': {
      backgroundPosition: '0% 50%'
    },
    '50%': {
      backgroundPosition: '100% 50%'
    },
    '100%': {
      backgroundPosition: '0% 50%'
    }
  }
}));
