import { styled as muiStyled } from '@mui/material';

export const avatarStyle = {
  width: 70,
  height: 70,
  marginRight: '1.2rem'
};

export const Wave = muiStyled('span')(() => ({
  animation: 'wave-animation 2.5s infinite',
  transformOrigin: '70% 70%',
  display: 'inline-block',

  '@keyframes wave-animation': {
    ' 0%': { transform: 'rotate( 0.0deg)' },
    '10%': {
      transform: 'rotate(14.0deg)'
    },
    '20%': { transform: 'rotate(-8.0deg)' },
    '30%': { transform: 'rotate(14.0deg)' },
    '40%': { transform: 'rotate(-4.0deg)' },
    '50%': { transform: 'rotate(10.0deg)' },
    '60%': { transform: 'rotate( 0.0deg)' },
    '100%': { transform: 'rotate( 0.0deg)' }
  }
}));

export const Surname = muiStyled('span')(({ theme }) => ({
  display: 'none',

  [theme.breakpoints.up('sm')]: {
    display: 'inline-block'
  }
}));

export const StyledHeader = muiStyled('div')(({ theme }) => ({
  marginTop: '2rem',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',
  marginBottom: '2rem',

  [theme.breakpoints.up('sm')]: {
    marginTop: '5rem'
  }
}));
