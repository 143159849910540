import React from 'react';
import { IconType } from 'react-icons';
import { FaGithub, FaLinkedinIn, FaTwitter } from 'react-icons/fa';
import { BsMedium } from 'react-icons/bs';
import { SocialIcon, SocialLinksContainer } from './SocialLinksStyle';

interface iconListProps {
  key: string;
  label: string;
  Icon: IconType;
  url: string | undefined;
}

const iconList: iconListProps[] = [
  {
    key: 'github-link',
    label: 'github',
    Icon: FaGithub,
    url: process.env.REACT_APP_GITHUB_URL,
  },
  {
    key: 'twitter-link',
    label: 'twitter',
    Icon: FaTwitter,
    url: process.env.REACT_APP_TWITTER_URL,
  },
  {
    key: 'medium-link',
    label: 'medium',
    Icon: BsMedium,
    url: process.env.REACT_APP_MEDIUM_URL,
  },
  {
    key: 'linkedin-link',
    label: 'linkedin',
    Icon: FaLinkedinIn,
    url: process.env.REACT_APP_LINKEDIN_URL,
  },
];

const renderIcons = iconList.map((icon) => (
  <SocialIcon
    href={icon.url}
    target="_blank"
    rel="noopener noreferrer"
    key={icon.key}
    aria-label={icon.label}
  >
    <icon.Icon />
  </SocialIcon>
));

const SocialLinks = () => (
  <SocialLinksContainer aria-label="social icons">{renderIcons}</SocialLinksContainer>
);

export default SocialLinks;
