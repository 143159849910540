import React from 'react';
import { Avatar, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { avatarStyle, StyledHeader, Surname, Wave } from './HeaderStyle';

const Header = () => {
  const theme = useTheme();
  return (
    <StyledHeader>
      <Avatar alt="John" src={process.env.REACT_APP_AVATAR_URL} style={avatarStyle} />
      <Typography variant="h4" fontWeight="bold" color={theme.palette.text.primary}>
        Hi <Wave>👋</Wave>, I&apos;m John <Surname>Oshalusi</Surname>
      </Typography>
    </StyledHeader>
  );
};

export default Header;
