import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material';
import React, { ReactNode, useMemo } from 'react';
import { useSettings } from '../context/SettingsContext';
import palette from './palette';

type ThemeConfigProps = {
  children: ReactNode;
};

export const ThemeConfig = ({ children }: ThemeConfigProps) => {
  const { themeMode } = useSettings();
  const isLight = themeMode === 'light';

  const themeOptions: ThemeOptions = useMemo(
    () => ({
      palette: isLight ? { ...palette.light, mode: 'light' } : { ...palette.dark, mode: 'dark' }
    }),
    [isLight]
  );

  const theme = createTheme(themeOptions);

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
