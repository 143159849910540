import { styled as muiStyled } from '@mui/material';

export const Wrap = muiStyled('div')(({ theme }) => ({
  marginTop: 'auto',
  color: theme.palette.text.primary,
  display: 'flex',
  justifyContent: 'flex-start',
  flexWrap: 'wrap',
  fontWeight: '600',
  alignItems: 'center'
}));

export const Heading = muiStyled('h1')(({ theme }) => ({
  fontWeight: '800',
  fontSize: '3.75rem',
  position: 'relative',

  [theme.breakpoints.up('sm')]: {
    padding: '0 1rem',
    fontSize: '4rem',
    borderRight: `2px solid ${theme.palette.divider}`
  }
}));

export const HeadingTwo = muiStyled('h2')(() => ({
  fontSize: '1.4rem',
  fontWeight: '600'
}));

export const MessageWrap = muiStyled('div')(({ theme }) => ({
  padding: '0',

  [theme.breakpoints.up('sm')]: {
    padding: '0 1rem',
    maxWidth: '80%'
  }
}));
